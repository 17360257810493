<template>
  <div>
    <div class="caption">
      {{ label }}
      <span v-b-tooltip.hover v-bind:title="tooltip" clickable v-if="tooltip">
        <b-icon-question-circle></b-icon-question-circle>
      </span>
    </div>
    <div class="data hide-copy" v-if="amount">
      <template v-if="amount && !amountTooltip">
        <span v-if="!noParse">{{ toBn(amount) || 0 }}</span>
        <span v-else-if="noParse">{{ amount }}</span>
      </template>
      <template v-else-if="amount && amountTooltip">
        <span v-if="!noParse" v-b-tooltip.hover v-bind:title="toBn(amountTooltip)" v-b-tooltip="{customClass: 'dark-tooltip'}" clickable>{{ toBn(amount) || 0 }}</span>
        <span v-else-if="noParse" v-b-tooltip.hover v-bind:title="amountTooltip" v-b-tooltip="{customClass: 'dark-tooltip'}" clickable>{{ amount }}</span>
      </template>
      <span v-else>-</span>
      <span v-if="append">{{ append }}</span>
      <span v-if="copy" class="copy-icon" v-clipboard="copy">
        <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.38272 0.0625V1.14894H1.06173V8.70346H0V1.14894C0 0.845745 0.102881 0.588874 0.308642 0.378324C0.514403 0.167775 0.765432 0.0625 1.06173 0.0625H7.38272ZM8.96296 2.23537C9.2428 2.23537 9.4856 2.34065 9.69136 2.5512C9.89712 2.76175 10 3.0102 10 3.29654V10.8511C10 11.1543 9.89712 11.4111 9.69136 11.6217C9.4856 11.8322 9.2428 11.9375 8.96296 11.9375H3.16049C2.8642 11.9375 2.61317 11.8322 2.40741 11.6217C2.20165 11.4111 2.09877 11.1543 2.09877 10.8511V3.29654C2.09877 3.0102 2.20165 2.76175 2.40741 2.5512C2.61317 2.34065 2.8642 2.23537 3.16049 2.23537H8.96296ZM8.96296 10.8511V3.29654H3.16049V10.8511H8.96296Z" fill="#80808F"/>
        </svg>
      </span>
      <span v-if="parseFloat(forecast)" class="forecast"
            :class="{error: hasError}">({{ hasError ? '+' : '' }}{{ forecast }})</span>
    </div>
    <div v-else>
      <span>-</span>
    </div>
  </div>
</template>

<script>
import BigNumber from "bignumber.js";

export default {
  name: "TextBlock",
  props: {
    label: {type: String, default: ''},
    tooltip: {type: String, default: ''},
    amount: {type: String, default: null},
    append: {type: String, default: ''},
    forecast: {type: String, default: null},
    noParse: {type: Boolean, default: false},
    copy: null,
    amountTooltip: null
  },
  methods: {
    toBn(number) {
      return new BigNumber(number).toFixed().toString()
    }
  },
  computed: {
    hasError() {
      let error = false
      if (this.forecast) {
        error = parseFloat(this.forecast) > 0
      }
      return error
    }
  }
}
</script>

<style scoped lang="scss">
  .data {
    span + span {
      margin-left: 5px;
    }
    &.hide-copy{
      .copy-icon {
        display: none;
      }
      &:hover {
        .copy-icon {
          display: inline;
        }
      }
    }
  }

  .copy-icon {
    margin-right: 2px;
    cursor: pointer;
    svg {
      margin-top: -4px;
    }
  }

  .forecast {
    color: $color-primary-dark;

    &.error {
      color: $color-danger
    }
  }

</style>
